<template>
  <section class="savePage">
    <div class="container">
      <div class="row">
        <div class="savePage__title">
          <span v-if="!isShowLink">
            {{ $t('message.save.title') }}
          </span>
          <span v-else>
            {{ $t('message.save.success') }}
          </span>
        </div>
      </div>
      <div class="row" v-if="!isShowLink">
        <div class="savePage__form-wrapper">
          <div class="item">
            <form @submit.prevent="checkForm" novalidate="true">
              <div
                class="input__wrapper"
                :class="{
                  'form-group--error': $v.objectName.$error,
                  complete: objectName !== '',
                  active: currentInput === 'objectName'
                }"
              >
                <div class="input__wrapper-label form__label">
                  {{ $t('message.save.form.objectName') }}
                </div>
                <div class="input__wrapper-input">
                  <div class="error" v-if="!$v.objectName.required">
                    {{ $t('message.save.form.errors.required') }}
                  </div>
                  <div class="error" v-if="!$v.objectName.minLength">
                    {{ $t('message.save.form.errors.length') }}
                    <!-- {{ $v.objectName.$params.minLength.min
                    }}{{ $t('message.save.form.errors.lengthCase1') }} -->
                    <i18n path="message.save.form.errors.lengthCase1">
                      <template #length>{{ $v.objectName.$params.minLength.min }}</template>
                    </i18n>
                    {{ $t('message.save.form.errors.symbols') }}
                  </div>
                  <input
                    class="form__input"
                    @focus="currentInput = 'objectName'"
                    v-model.trim="$v.objectName.$model"
                    @input="delayTouch($v.objectName)"
                  />
                </div>
              </div>
              <div
                class="input__wrapper"
                :class="{
                  'form-group--error': $v.address.$error,
                  complete: address !== '',
                  active: currentInput === 'address'
                }"
              >
                <div class="input__wrapper-label form__label">
                  {{ $t('message.save.form.objectAddress') }}
                </div>
                <div class="input__wrapper-input">
                  <div class="error" v-if="!$v.address.required">
                    {{ $t('message.save.form.errors.required') }}
                  </div>
                  <div class="error" v-if="!$v.address.minLength">
                    <!-- {{ $t('message.save.form.errors.length') }} {{ $v.address.$params.minLength.min
                    }}{{ $t('message.save.form.errors.lengthCase1') }} -->
                    {{ $t('message.save.form.errors.length') }}
                    <i18n path="message.save.form.errors.lengthCase1">
                      <template #length>{{ $v.address.$params.minLength.min }}</template>
                    </i18n>
                    {{ $t('message.save.form.errors.symbols') }}
                  </div>
                  <input
                    class="form__input"
                    @focus="currentInput = 'address'"
                    v-model.trim="$v.address.$model"
                    @input="delayTouch($v.address)"
                  />
                </div>
              </div>
              <div
                class="input__wrapper"
                :class="{
                  'form-group--error': $v.userName.$error,
                  complete: userName !== '',
                  active: currentInput === 'userName'
                }"
              >
                <div class="input__wrapper-label form__label">
                  {{ $t('message.save.form.userName') }}
                </div>
                <div class="input__wrapper-input">
                  <div class="error" v-if="!$v.userName.required">
                    {{ $t('message.save.form.errors.required') }}
                  </div>
                  <div class="error" v-if="!$v.userName.minLength">
                    {{ $t('message.save.form.errors.length') }}
                    <!-- {{ $v.userName.$params.minLength.min
                    }}{{ $t('message.save.form.errors.lengthCase2') }} -->
                    <i18n path="message.save.form.errors.lengthCase2">
                      <template #length>{{ $v.userName.$params.minLength.min }}</template>
                    </i18n>
                    {{ $t('message.save.form.errors.symbols') }}
                  </div>
                  <input
                    class="form__input"
                    @focus="currentInput = 'userName'"
                    v-model.trim="$v.userName.$model"
                    @input="delayTouch($v.userName)"
                  />
                </div>
              </div>
              <div
                class="input__wrapper"
                :class="{
                  'form-group--error': $v.userEmail.$error,
                  complete: userEmail !== '',
                  active: currentInput === 'userEmail'
                }"
              >
                <div class="input__wrapper-label form__label">E-mail</div>
                <div class="input__wrapper-input">
                  <div class="error" v-if="!$v.userEmail.required">
                    {{ $t('message.save.form.errors.required') }}
                  </div>
                  <div class="error" v-if="!$v.userEmail.email">
                    {{ $t('message.save.form.errors.invalidMail') }}
                  </div>
                  <input
                    class="form__input"
                    @focus="currentInput = 'userEmail'"
                    v-model.trim="$v.userEmail.$model"
                    @input="delayTouch($v.userEmail)"
                  />
                </div>
              </div>
              <div
                class="input__wrapper"
                :class="{
                  'form-group--error': $v.userPhone.$error,
                  complete: userPhone !== '',
                  active: currentInput === 'userPhone'
                }"
              >
                <div class="input__wrapper-label form__label">
                  {{ $t('message.save.form.userPhone') }}
                </div>
                <div class="input__wrapper-input">
                  <div class="error" v-if="!$v.userPhone.required">
                    {{ $t('message.save.form.errors.required') }}
                  </div>
                  <div class="error" v-if="!$v.userPhone.minLength">
                    {{ $t('message.save.form.errors.length') }}
                    <!-- {{ $v.userPhone.$params.minLength.min
                    }}{{ $t('message.save.form.errors.lengthCase3') }} -->
                    <i18n path="message.save.form.errors.lengthCase3">
                      <template #length>{{ $v.userPhone.$params.minLength.min }}</template>
                    </i18n>
                    {{ $t('message.save.form.errors.symbols') }}
                  </div>
                  <input
                    class="form__input"
                    type="number"
                    @focus="currentInput = 'userPhone'"
                    v-model.number="$v.userPhone.$model"
                    @input="delayTouch($v.userPhone)"
                  />
                </div>
              </div>
              <div
                class="input__wrapper"
                :class="{ complete: company !== '', active: currentInput === 'company' }"
              >
                <div class="input__wrapper-label">
                  {{ $t('message.save.form.company') }}
                </div>
                <div class="input__wrapper-input" v-click-outside="hideSuggestions">
                  <input
                    type="text"
                    :value="company"
                    @input="updateCompanyText"
                    @focus="handleCompanyInputFocus"
                    placeholder=""
                  />
                  <transition name="fade">
                    <company-suggestions
                      v-if="isShowSuggestions"
                      :suggestions="suggestions"
                      @updateCompany="updateCompany"
                    />
                  </transition>
                </div>
              </div>
              <div class="form-checkbox">
                <input
                  type="checkbox"
                  class="checkbox"
                  id="form-privacy"
                  v-model="$v.privacy.$model"
                />
                <label for="form-privacy" class="tick"></label>
                <label
                  for="form-privacy"
                  :class="{ 'checkbox-label': true, 'checkbox-label--error': $v.privacy.$error }"
                >
                  Я согласен на обработку своих персональных данных. <br />
                  Нажимая кнопку "Сохранить расчет", я подтверждаю, что ознакомился с
                  <a href="https://nav.tn.ru/privacy/" target="_blank">
                    Политикой обработки персональных данных ЗАО "ТехноНИКОЛЬ"
                  </a>
                  и даю согласие на обработку всех моих персональных данных указанных в форме.
                </label>
              </div>
              <div class="info__wrapper-box info__wrapper-box--form">
                <img src="@/assets/img/icons/info.svg" alt="i" class="info__wrapper-img" />
                <div class="info__wrapper-text">
                  {{ $t('message.save.info') }}
                </div>
              </div>
              <!-- <div class="captcha">
                <div class="captcha__layout" ref="captcha"></div>
                <div class="captcha__error" v-if="isCaptchaErrorShow">
                  {{ $t('message.save.form.errors.captcha') }}
                </div>
              </div> -->
              <button type="submit" class="btn">
                <span>{{ $t('message.save.buttons.save') }}</span>
                <img src="@/assets/img/icons/cloud.svg" alt="cloud" />
              </button>
            </form>
          </div>
          <div class="item info__wrapper">
            <div class="info__wrapper-box info__wrapper-box--outer">
              <img src="@/assets/img/icons/info.svg" alt="i" class="info__wrapper-img" />
              <div class="info__wrapper-text">
                {{ $t('message.save.info') }}
              </div>
            </div>
            <router-link :to="`/${$i18n.locale}/result`" class="btn__back">
              <inline-svg :src="require('@/assets/img/save/calculator.svg')" />
              <span>{{ $t('message.save.buttons.back') }}</span>
            </router-link>
          </div>
        </div>
      </div>
      <transition name="fade">
        <div class="row copyLink" v-if="isShowLink">
          <div class="copyLink__wrapper">
            <div class="copyLink__wrapper-img">
              <img src="@/assets/img/link.svg" alt="link" />
            </div>
            <input
              type="text"
              ref="link"
              :value="`${domenName}/result/?object=${hash}`"
              class="copyLink__wrapper-input"
              readonly="readonly"
            />
            <button @click="copy()" class="copyLink__wrapper-btn">
              {{ $t('message.save.buttons.copy') }}
              <span>{{ $t('message.save.buttons.link') }}</span>
            </button>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div class="row copyLink" v-if="isShowLink">
          <div class="info__wrapper-box info__wrapper-box--save">
            <img src="@/assets/img/icons/info.svg" alt="i" class="info__wrapper-img" />
            <div class="info__wrapper-text">
              {{ $t('message.save.successInfo') }}
            </div>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div class="row copyLink" v-if="isShowLink">
          <router-link :to="`/${$i18n.locale}/result`" class="btn__back btn__back-save">
            <inline-svg :src="require('@/assets/img/save/calculator.svg')" />
            <span>{{ $t('message.save.buttons.back') }}</span>
          </router-link>
          <button @click="newCalculate()" class="btn__back btn__back-save">
            <inline-svg :src="require('@/assets/img/save/back.svg')" />
            <span>{{ $t('message.save.buttons.new') }}</span>
          </button>
        </div>
      </transition>
    </div>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
  </section>
</template>

<script>
import { getCompanySuggestions } from '@/api/suggestions-api'
import { mapState, mapMutations, mapActions } from 'vuex'
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'

import InlineSvg from 'vue-inline-svg'
import CompanySuggestions from '@/components/dump/SaveForm/CompanySuggestions'
import { stepNumbers } from '@/utils/stepNumbers'
const touchMap = new WeakMap()

export default {
  data: () => ({
    isCaptchaPassed: true,
    // isCaptchaPassed: false,
    isCaptchaErrorShow: false,
    currentInput: '',
    domenName: '',
    hashFromDB: '',
    isHover: false,
    address: '',
    objectName: '',
    company: '',
    userName: '',
    userPhone: '',
    userEmail: '',
    suggestions: [],
    isShowSuggestions: false,
    privacy: false
    // isShowLink: false,
  }),
  validations: {
    userName: {
      required,
      minLength: minLength(4)
    },
    userPhone: {
      required,
      minLength: minLength(8)
    },
    userEmail: {
      required,
      email
    },
    address: {
      required,
      minLength: minLength(6)
    },
    objectName: {
      required,
      minLength: minLength(6)
    },
    privacy: {
      sameAs: sameAs(() => true)
    }
  },
  methods: {
    ...mapActions({
      saveResult: 'saveResult',
      updateStep: 'updateStep'
    }),
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    },
    ...mapMutations({
      RESET_SECTORS: 'RESET_SECTORS'
    }),
    async checkForm() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.currentInput = ''
        // validation no pass
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      } else {
        if (this.isCaptchaPassed) {
          this.currentInput = ''
          const { userName, userPhone, userEmail, objectName, address, company } = this
          this.saveResult({
            lang: this.$i18n.locale,
            user: {
              email: userEmail,
              name: userName,
              phone: userPhone,
              address: address,
              objectName: objectName,
              company: company
            },
            url: `${location.origin}/${this.$i18n.locale}/result/?object=`
          })

          const userData = {
            userName,
            userPhone,
            userEmail,
            company
          } // objectName,address,
          localStorage.setItem('userData', JSON.stringify(userData))
          this.updateStep(stepNumbers.afterSave)
        } else {
          this.isCaptchaErrorShow = true
        }
      }
    },
    copy() {
      const copyCode = this.$refs.link
      copyCode.select()
      document.execCommand('copy')
      this.$notify({
        group: 'copyToClip',
        title: 'ссылка скопирована в буфер',
        text: require('@/assets/img/notify.svg')
      })
    },
    newCalculate() {
      this.RESET_SECTORS()
      this.$router.push('/')
    },
    getSuggestions() {
      getCompanySuggestions(this.company).then(response => {
        this.suggestions = Object.freeze(response.data.suggestions)
      })
      // .catch(error => console.log('error', error))
    },
    updateCompanyText(e) {
      this.company = e.target.value
      this.showSuggestions()
    },
    handleCompanyInputFocus() {
      this.currentInput = 'company'
      this.showSuggestions()
    },
    updateCompany(val) {
      this.company = val
      this.hideSuggestions()
    },
    showSuggestions() {
      if (!this.isShowSuggestions) {
        this.isShowSuggestions = true
      }
    },
    hideSuggestions() {
      if (this.isShowSuggestions) {
        this.isShowSuggestions = false
      }
    }
  },
  mounted() {
    this.domenName = `${location.origin}/${this.$i18n.locale}`
    if (this.allSystems.length === 0) {
      this.$router.push('/')
    } else {
      const { grecaptcha } = window
      grecaptcha.ready(() => {
        grecaptcha.render(this.$refs.captcha, {
          sitekey: process.env.VUE_APP_RECAPTCHA_KEY,
          'expired-callback': () => {
            this.isCaptchaPassed = false
          },
          'error-callback': () => {
            this.isCaptchaPassed = false
          },
          callback: () => {
            this.isCaptchaPassed = true
            this.isCaptchaErrorShow = false
          }
        })
      })
    }
    const savedUserInfo = localStorage.getItem('userData')
    if (savedUserInfo) {
      // objectName, address,
      // this.objectName = objectName
      // this.address = address
      const { userName, userPhone, userEmail, company } = JSON.parse(savedUserInfo)
      this.userName = userName
      this.userPhone = userPhone
      this.userEmail = userEmail
      this.company = company
    }
    this.updateStep(stepNumbers.save)
  },
  watch: {
    company() {
      this.getSuggestions()
    }
  },
  computed: {
    ...mapState({
      hash: state => state.hash,
      allSystems: state => state.systems,
      isLoading: state => state.pages.save.isLoading,
      isLoggedIn: state => state.moderator.isAuth
    }),
    isShowLink() {
      return this.hash !== null
    }
  },
  components: {
    CompanySuggestions,
    InlineSvg
  }
}
</script>
<style lang="sass">
.savePage
  .btn
    &__back
      svg
        +media((margin-right: (320: rem(20), 360: rem(28))))
        path, rect
          transition: .5s
      &:hover
        svg
          path
            stroke: #fff
          rect
            fill: #fff
      &:hover
        svg
          path
            stroke: #fff
</style>
<style lang="sass" scoped>
.savePage
  color: $black
  &__title
    font-style: normal
    font-weight: 500
    text-align: left
    +media((padding: (320: rem(32) rem(24), 992: rem(50) 0)))
    +media((font-size: (320: rem(22), 960: rem(28))))
    +media((line-height: (320: rem(29), 960: rem(34))))
  &__form
    &-wrapper
      .form-checkbox
        display: flex
        align-items: flex-start
        flex-direction: row
        +media((margin: (320: 0 0 rem(40), 768: rem(34) 0 0)))
        .tick
          background: $white
          height: rem(18)
          width: rem(18)
          border-radius: rem(4)
          flex: 0 0 rem(18)
          border: rem(1) solid #D6D6D6
          cursor: pointer
          display: flex
          justify-content: center
          align-items: center
          transition: .5s
          @media (any-hover: hover)
            &:hover
              &:before
                opacity: 1
          &:before
            content: url("~@/assets/img/form-tick--red.svg")
            position: relative
            display: block
            line-height: 0
            pointer-events: none
            opacity: 0
            transition: .5s
        .checkbox
          position: absolute
          pointer-events: none
          display: none
          height: 0
          width: 0
          &:checked
            + .tick
              background: $red
              border-color: $red
              &:before
                opacity: 1
                content: url("~@/assets/img/form-tick.svg")
          &-label
            text-align: left
            font-size: rem(12)
            margin-left: rem(10)
            a
              color: $red
            &--error
              color: red
      width: 100%
      box-sizing: border-box
      display: grid
      +media((grid-template-columns: (320: 1fr, 960: 7fr 5fr)))
      +media((padding: (320: 0 rem(24), 960: 0)))
      +media((padding-bottom: (320: rem(32), 768: rem(68))))
      .item
        +media((padding: (320: 0)))
        .captcha
          margin: rem(20) 0
          +media((max-width: (0: rem(272), 360: unset)))
          +media((max-height: (0: rem(70), 360: unset)))
          &__layout
            transform-origin: 0 0
            +media((transform: (0: scale(0.89), 360: none)))
          &__error
            font-size: rem(12)
            color: $red
            text-align: left
        .input
          &__wrapper
            width: 100%
            display: flex
            align-items: center
            position: relative
            +media((margin-bottom: (320: rem(8), 768: 0)))
            +media((height: (320: auto, 768: rem(68))))
            +media((flex-direction: (320: column, 768: row)))
            &.complete, &.active
              .input
                &__wrapper
                  &-label
                    +media((font-size: (320: rem(10), 768: rem(14))))
                    +media((top: (320: rem(8), 768: unset)))
                    transform: translateY(0)
            &-label, &-input
              height: 100%
              display: flex
              flex-direction: row
              align-items: center
              background: #F6F6F6
              border: rem(1) solid #ECECEC
              box-sizing: border-box
              border-radius: rem(4) 0 0 rem(4)
            .error
              position: absolute
              top: 0
              width: 100%
              font-size: rem(12)
              font-family: $main-font
              box-sizing: border-box
              font-weight: 500
              color: red
              text-align: right
              padding-top: rem(5)
              padding-right: rem(10)
              opacity: 0
              pointer-events: none
              +media((display: (320: none, 768: block)))
            &.form-group--error
              .error
                opacity: 1
              .form__label
                +media((color: (320: red, 768: $black)))
              .form__input
                +media((border-color: (320: #EA5B62, 768: transparent)))
                +media((border-width: (320: rem(2), 768: rem(1))))
            &-label
              text-align: left
              font-style: normal
              font-weight: bold
              line-height: rem(18)
              padding-bottom: 0
              transition: .5s
              +media((border: (320: none, 768: rem(1) solid #ECECEC)))
              +media((height: (320: auto, 768: 100%)))
              +media((color: (320: #999999, 992: $black)))
              +media((width: (320: 100%, 768: rem(200))))
              +media((max-width: (320: unset, 768: rem(200))))
              +media((background: (320: transparent, 768: #F6F6F6)))
              +media((padding-left: (320: rem(20), 768: rem(30))))
              +media((font-size: (320: rem(12), 768: rem(14))))
              pointer-events: none
              +media((position: (320: absolute, 768: relative)))
              +media((top: (320: 50%, 768: unset)))
              +media((transform: (320: translateY(-50%), 768: translateY(0))))
              z-index: 2
            &-input
              position: relative
              box-sizing: border-box

              +media((width: (320: 100%, 768: calc(100% - 200px))))
              // +media((margin-bottom: (320: rem(23), 768: 0)))
              //+media((margin-bottom: (320: rem(23), 768: 0)))
              input
                height: 100%
                width: 100%
                border: rem(1) solid #ECECEC
                box-sizing: border-box
                outline: none
                box-shadow: none
                text-align: left
                font-style: normal
                font-weight: bold
                line-height: rem(18)
                color: $black
                caret-color: $red
                transition: .5s
                text-overflow: ellipsis
                +media((border-color: (320: #D6D6D6, 768: transparent)))
                +media((font-size: (320: rem(12), 768: rem(14))))
                +media((padding: (320: rem(12) rem(16) 0, 768: 0 rem(25))))
                +media((border-radius: (320: rem(4), 768: 0)))
                +media((height: (320: rem(54), 768: 100%)))
                &:focus, &:hover
                  box-shadow: 0 rem(4) rem(12) rgba(0, 0, 0, 0.12)
      .btn
        background: $green
        box-shadow: none
        outline: none
        border: none
        +media((width: (320: 100%, 768: 50%, 960: 100%)))
        color: #fff
        font-family: $main-font
        font-style: normal
        font-weight: bold
        line-height: rem(22)
        border-radius: rem(4)
        transition: .5s
        padding-left: rem(25)
        padding-right: rem(30)
        height: rem(68)
        margin-top: rem(34)
        cursor: pointer
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        +media((height: (320: rem(64), 768: rem(68))))
        +media((font-size: (320: rem(15), 360: rem(18))))
        &:hover
          background: $green-hover
  .btn
    &__back
      background: #F6F6F6
      border: rem(1) solid #F6F6F6
      box-sizing: border-box
      border-radius: rem(4)
      display: flex
      flex-direction: row
      align-items: center
      text-decoration: none
      color: $red
      font-style: normal
      font-weight: 500
      font-size: rem(12)
      line-height: rem(20)
      transition: .5s
      font-family: $main-font
      cursor: pointer
      max-width: rem(320)
      +media((margin-right: (320: 0, 688: rem(10))))
      // +media((min-width: (320: 100%, 960: rem(320))))
      +media((margin-top: (320: rem(16), 960: 0)))
      +media((padding-left: (320: rem(17), 360: rem(30))))
      +media((height: (320: rem(64), 768: rem(68))))
      // +media((min-width: (320: 100%, 768: 50%, 992: rem(320))))
      +media((width: (320: 100%, 768: 50%, 960: rem(320))))
      &:hover
        background: $red
        color: #fff
        border-color: $red
      &:hover
        background: $red
        color: #fff
        border-color: $red
      &-save
        +media((margin-top: (320: rem(32), 708: rem(44))))
        +media((margin-bottom: (320: rem(10), 708: rem(66))))
        &:nth-child(2)
          +media((margin-top: (320: rem(0), 708: rem(44))))
          +media((margin-bottom: (320: rem(48), 708: rem(66))))
  .info
    &__wrapper
      display: flex
      flex-direction: column
      justify-content: space-between
      +media((padding: (320: 0, 960: 0 0 0 rem(80))))
      &-box
        display: flex
        flex-direction: row
        align-items: flex-start
        // +media((padding-top: (320: rem(40), 768: 0)))
        &--save
          +media((align-items: (320: flex-start, 768: center)))
          +media((padding-top: (320: rem(32), 768: rem(44))))
        &--form
          +media((display: (320: flex, 960: none)))
          +media((padding-top: (768: rem(23))))
        &--outer
          +media((display: (320: none, 960: flex)))
      &-text
        font-style: normal
        font-weight: 500
        font-size: rem(12)
        line-height: rem(24)
        text-align: left
        padding-left: rem(20)
  .copyLink
    +media((padding: (320: 0 rem(24), 992: 0)))

    &__wrapper
      //display: flex
      //flex-direction: row
      //align-items: center
      //justify-content: space-between
      display: grid
      overflow: hidden
      background: #FFFFFF
      border: rem(1) solid #ECECEC
      box-sizing: border-box
      border-radius: rem(4)
      width: 100%
      +media((grid-template-columns: (320: 7fr 5fr, 768: 2fr 17fr 5fr)))
      +media((height: (320: rem(48), 768: rem(68))))
      &-img
        width: rem(80)
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        +media((display: (320: none, 768: flex)))
      &-input
        background: none
        box-shadow: none
        border: none
        font-style: normal
        text-overflow: ellipsis
        font-weight: bold
        font-size: rem(14)
        line-height: rem(18)
        height: 100%
        // pointer-events: none
        outline: none
        overflow: hidden
        +media((padding: (320: 0 rem(24), 768: 0 rem(24) 0 0)))
        &:disabled
          color: $black
      &-btn
        border: none
        outline: none
        box-shadow: none
        background: $red
        height: 100%
        transition: .5s
        font-style: normal
        font-weight: bold
        font-size: rem(14)
        line-height: rem(17)
        color: #FFFFFF
        width: rem(200)
        cursor: pointer
        +media((width: (320: 100%, 768: rem(200))))
        &:hover
          background: $red-hover
        span
          +media((display: (320: none, 768: inline-block)))
.fade-enter-active, .fade-leave-active
  transition: opacity .5s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
