import axios from 'axios'

// const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party'
const baseUrl = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest'
const token = 'b577730f4ebd2d482d7ec054e29b7fe942866f60'

const suggestionsApi = axios.create({
  baseURL: baseUrl,
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Token ${token}`
  }
})
export const getCompanySuggestions = query => {
  const data = {
    query
  }
  return suggestionsApi.post('/party', data)
}

export const getAddressSuggestions = query => {
  const data = {
    query
  }
  return suggestionsApi.post('/address', data)
}
