export const stepNumbers = {
  home: 0,
  systems: 1,
  singleSustem: 2,
  wedge: 3,
  junctions: 4,
  singleJunction: 5,
  result: 6,
  save: 7,
  afterSave: 8
}
