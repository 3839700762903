import { render, staticRenderFns } from "./CompanySuggestions.vue?vue&type=template&id=d41073fe&scoped=true&"
import script from "./CompanySuggestions.vue?vue&type=script&lang=js&"
export * from "./CompanySuggestions.vue?vue&type=script&lang=js&"
import style0 from "./CompanySuggestions.vue?vue&type=style&index=0&id=d41073fe&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d41073fe",
  null
  
)

export default component.exports