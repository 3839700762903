<template>
  <div class="suggestions">
    <div class="suggestions__hint" v-if="!!suggestions.length">
      {{ $t('message.save.form.suggestions') }}
    </div>
    <div
      class="suggestions__item"
      v-for="(suggestion, i) in suggestions"
      :key="`${suggestion.data.inn}--${suggestion.data.ogrn}--${i}`"
      @click="updateCompany(suggestion.value)"
    >
      <div class="suggestions__item-name">
        {{ suggestion.value }}
      </div>
      <div class="suggestions__item-footer">
        <div class="number">
          {{ suggestion.data.inn }}
        </div>
        <div class="address">
          {{ suggestion.data.address.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanySuggestions',
  props: {
    suggestions: {
      type: Array,
      required: true
    }
  },
  methods: {
    updateCompany(val) {
      this.$emit('updateCompany', val)
    }
  }
  // computed: {
  //   hintText() {
  //     return !this.suggestions.length
  //       ? 'Поиск не дал результатов'
  //       : 'Выберите вариант или продолжите ввод'
  //   }
  // }
}
</script>

<style scoped lang="sass">
.suggestions
  display: grid
  text-align: left
  width: 100%
  position: absolute
  background: $white
  transform: translateY(100%)
  +media((bottom: (0: rem(-6), 768: 0)))
  +media((border-radius: (0: rem(4), 768: 0)))
  z-index: 1
  max-height: rem(256)
  box-sizing: border-box
  overflow-y: auto
  overflow-x: hidden
  scrollbar-width: thin
  scrollbar-color: rgba($red, .25) transparent
  &::-webkit-scrollbar
    width: rem(4)
  &::-webkit-scrollbar-thumb
    background: $cool_A
  &::-webkit-scrollbar-thumb
    background-color: rgba($red, .25)
    border-radius: 0
  &::-webkit-scrollbar-track
    background: $cool_A
  &__hint
    @extend %12
    color: $light_black
    padding: 0 rem(12)
    margin: rem(6) 0
  &__item
    cursor: pointer
    box-sizing: border-box
    padding: rem(6) rem(6) rem(6) rem(12)
    transition: .5s
    display: grid
    @media(any-hover: hover)
      &:hover
        background: $red
        box-shadow: none
        .suggestions
          &__item
            &-name, &-footer
              color: $white
    &:not(:last-child)
      box-shadow: inset 0 rem(-1) 0 $ec
    &-name
      @extend %14
      color: $black
      margin-bottom: rem(6)
      transition: .5s
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      font-weight: bold
      // max-width: rem(322)
      max-width: 100%
    &-footer
      display: grid
      $t-s: rem(70) 1fr
      $t-l: rem(85) 1fr
      +media((grid-template-columns: (0: $t-s, 768: $t-l)))
      @extend %12
      color: $light_black
      transition: .5s
      .address
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        max-width: 100%
</style>
